import React from 'react';

const GoogleMap = () => {
  return (
    <div className="row">
      {/* Contenedor responsivo */}
      <div className="embed-responsive embed-responsive-16by9">
        {/* Mapa de Google */}
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d238.57322630388413!2d-99.82023361053677!3d16.916824497799176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ca56d6d02f6457%3A0x2f7e1bcf2960a515!2sCarrocer%C3%ADas%20Azcatl!5e0!3m2!1ses-419!2smx!4v1714081312542!5m2!1ses-419!2smx" 
          width="600" 
          height="400" 
          className="embed-responsive-item"
          style={{ border: '0' }} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default GoogleMap;
